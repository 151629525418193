.lineSeparator {
    border-bottom: 1px solid #e5e5e5;
    margin: 20px 0;
}

.greyColor {
    color: var(--color-grey-text);
}

.packsList {
    max-height: 20vh;
    overflow: auto;
    .pack {
        display: inline-block;
        width: 270px;
        font-family: 'standard';
        font-size: 0.95em;
        text-transform: none;
        margin-bottom: 5px;
    }
}

.twoCols {
    div {
        display: block;
        margin-bottom: 10px;
        span:first-of-type {
            font-family: 'bold';
            display: inline-block;
            text-align: right;
            margin-right: 10px;
            width: 150px;
        }
    }
}
