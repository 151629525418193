.container {
    position: relative;

    &:focus-within > .popup {
        display: block;
    }
}

.button {
    border-radius: 50%;
    height: 48px;
    width: 48px;
    cursor: pointer;
    padding: 0;
    margin: 5px 15px 5px 0;
}

.photo {
    margin: 0;
    padding: 0;
    border-radius: 50%;
    float: left;
    height: 44px;
    width: 44px;
    border: 2px solid var(--color-white);
    cursor: pointer;
    overflow: clip;
    background: var(--color-white);
}

.popup {
    display: none;
    position: absolute;
    background: var(--color-white);
    border-radius: 7px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 5px 11px;
    right: 16px;
    top: 80px;
    user-select: none;
    z-index: 1;

    & > * {
        display: block;
        margin: 0;
        padding: 10px 15px;
        border-radius: 7px;
        color: var(--color-nav-active);
        word-break: keep-all;
    }

    & > .signoutButton {
        display: block;
        margin: 0;
        text-decoration: underline;
        color: var(--color-black);
        background: none;
        width: 100%;
        text-align: left;

        &:hover {
            background: var(--color-body);
            cursor: pointer;
        }
    }
}
