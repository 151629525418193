.successBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonLink {
    color: var(--color-white);
    background-color: var(--color-primary);
    padding: 8px 16px;
    margin-top: 16px;
    border-radius: 4px;
    font-family: 'bold';
    text-decoration: none;
}

.header {
    margin-bottom: 8px;
}

.headerTitle {
    font-size: 1.5em;
    margin-bottom: 2px;
}

.paragraph {
    max-width: 60%;
}
