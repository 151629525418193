.colorPositive {
    color: var(--color-positive);
}

.secondaryButton {
    background-color: var(--color-secondary);
    color: var(--color-dark-text);
    margin-left: 20px;
    &:first-of-type {
        margin-left: 0;
    }
}

.deleteButton {
    background-color: var(--color-danger);
    display: inline-block;
}

.selectAccounts {
    font-weight: 400;
    margin: 10px 0;
    display: flex;
    align-items: center;
    height: 45px;
    font-size: 13px;
    &.selected {
        font-weight: 700;
    }
    label {
        width: 400px;
    }
}

.marginBottom20 {
    margin-bottom: 20px;
}

.warningInfo {
    display: flex;
    align-items: center;
    height: 45px;
    color: var(--color-grey-text);
    font-size: 13px;
    .icon {
        height: 16px;
        margin-right: 10px;
    }
}
