.media {
    display: flex;
}

.header {
    font-size: 1.5em;
}

.previewWrapper {
    flex: 3 1;
    align-self: center;
}

.preview {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1.5;
    background-color: #f1f3f3;
}

.metadataWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: space-between;
    padding: 8px 24px;
    gap: 64px;
}

.usages {
    max-height: 240px;
    overflow: auto;
    padding: 16px 12px;
    border-radius: 8px;
    background-color: var(--color-grey-background);
}

.usagesHeader {
    display: flex;
    gap: 10px;
    margin-bottom: 8px;
}

.usagesByEntity {
    margin: 4px 0px;
}

.usage {
    display: list-item;
    list-style-type: disc;
    padding-bottom: 4px;
}

.propertyKey {
    padding-bottom: 2px;
}

.propertyValue {
    font-family: standard;
    margin-inline-start: 16px;
    padding-bottom: 4px;
}

.deleteButton {
    display: flex;
    align-items: center;
    gap: 8px;
}

.deleteInfoParagraph {
    font-size: 0.8em;
    font-family: standard;
    color: var(--color-dark-text);
}
