.link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    border-radius: 8px;
    padding: 8px 12px;
    text-decoration: none;
    background-color: var(--color-grey-background);
    &.isActive {
        color: var(--color-white);
        background-color: var(--color-black);
    }
}
