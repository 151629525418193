.platformDataTable {
    margin-top: 10px;
    margin-bottom: 20px;
}

.buttonsBlock {
    display: flex;
    align-items: center;
    margin-top: 20px;

    & > *:not(:first-child) {
        margin-left: 20px;
    }

    .error {
        color: var(--color-danger);
    }
}
