.deleteButton {
    padding: 0;
    margin: 0;
    background: none;

    & > .img {
        height: 35px;
        margin-bottom: -13px;
        margin-right: 15px;
        cursor: pointer;
        user-select: none;
    }
}
