.paragraph {
    max-width: 60%;
}

.modalContent {
    background-color: var(--color-grey-background);
    padding: 32px;
    color: var(--color-dark-text);
    max-height: 50vh;
    overflow: auto;
}

.greyText {
    color: var(--color-dark-text);
}

.actionArea {
    z-index: 9;
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 15px 20px;
    background-color: var(--color-grey-background);
    border-radius: 15px;
    border: 5px solid rgba(0, 0, 0, 0.05);
}

.marginBottom20 {
    margin-bottom: 20px;
}

.notBoldText {
    font-family: 'standard';
}

.packsList {
    margin-top: 20px;
    .pack {
        display: inline-block;
        width: 305px;
        font-family: 'standard';
        font-size: 0.95em;
        text-transform: none;
        margin-bottom: 5px;
    }
}
