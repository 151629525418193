section {
    display: block;
    background: white;
    margin: 25px;
    border-radius: 7px;
    padding: 0;
    position: relative;

    h2 {
        margin-top: 10px;
        color: var(--color-h2);
        font-size: 1.2em;
        font-family: 'bold';
        display: block;
    }

    .content {
        position: relative;
        padding: 20px;
    }

    textarea {
        border-radius: 5px;
        padding: 7px;
        min-width: 350px;
        min-height: 200px;
        background: #f1f3f3;
        border: none;
        font-size: 0.9em;
        display: inline-block;
        line-height: 1.8em;
    }

    .header {
        cursor: pointer;
        background: var(--color-section-title);
        width: 100%;
        border-radius: 7px 7px 0 0;
        text-align: left;
        h1 {
            padding: 5px 20px 0 20px;
            font-size: 1.1em;
            color: var(--color-h1);
            user-select: none;
        }
        &.collapsed {
            border-radius: 5px !important;
            background-color: var(--color-section-title-resting);
        }
        .titleIcon {
            width: 25px;
            height: 25px;
            padding: 12px 0 0 12px;
            float: left;
            margin-right: -15px;
        }
    }

    label {
        margin-right: 20px;
        font-family: 'standard';
        &.placeholder {
            font-family: bold;
            margin-left: 10px;
            color: #88878c;
            margin-top: 4px;
        }
    }
}

img.arrow {
    user-select: none;
    height: 13px;
    margin-top: 19px;
    margin-right: 15px;
    float: right;
    &.down {
        transform: rotate(0deg);
    }
    &.right {
        transform: rotate(-90deg);
    }
    &.left {
        transform: rotate(90deg);
    }
    &.up {
        transform: rotate(180deg);
    }
}

.accordion {
    margin: 16px 0;

    .header {
        background: var(--color-accordion-section-title);
        h1 {
            font-size: 14px;
            line-height: 16px;
            margin: 8px 16px;
            padding: 0;
        }
        &.collapsed {
            background: var(--color-accordion-section-title-resting);
            &:hover {
                background: var(--color-accordion-section-title);
            }
        }
        img.arrow {
            height: 12px;
            margin-top: 10px;
            margin-right: 10px;
        }
    }

    .content {
        padding: 20px 0;
    }
}
