.summary {
    display: flex;
    align-items: center;
    min-height: 48px;
    padding-left: 12px;
}

.list {
    width: 100%;
    margin: 0;
}

.row {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    align-items: center;
    border-top: 1px solid;
}

.thumbnail {
    width: 40px;
}

.name {
    padding-left: 12px;
}

.data {
    margin-inline-start: 0px;
    min-height: 1em;
    white-space: pre-wrap;
    word-break: break-all;
}

.expandedData {
    font-family: 'standard';
}
