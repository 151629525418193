.button {
    margin: 0 8px;
}

.primary {
    background-color: var(--color-primary);
    &:disabled {
        background-color: var(--color-primary-light);
    }
}

.secondary {
    color: var(--color-default);
    background-color: var(--color-secondary);
    &:disabled {
        background-color: var(--color-secondary-light);
    }
}

.danger {
    color: var(--color-danger);
    background-color: var(--color-secondary);
    &:disabled {
        background-color: var(--color-secondary-light);
    }
}
