.header {
    display: flex;
    justify-content: space-between;
    gap: 8%;
    padding-bottom: 8px;
}

.filters {
    display: flex;
    align-items: start;
}

.typeFilters {
    flex-basis: 1;
    flex-grow: 1;
}

.dateFilters {
    display: flex;
    gap: 8px;
    flex-basis: 1;
    flex-grow: 0;
}

.dateFilter {
    display: flex;
    flex-direction: column;
}

.label {
    font-family: 'bold';
    &:after {
        content: ':';
    }
}

.sort {
    display: flex;
    flex-direction: column;
}
