.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: '#f1f3f3';
}

.image {
    width: 42px;
}

.header {
    margin-top: 16px;
    font-size: 1.4rem;
}

.body {
    font-family: standard;
    margin-top: 8px;
}
