div[data-env='development'] {
    --color-header: #eebfde;
    --color-nav-active: #7e7681;
}

div[data-env='prod'] {
    --color-header: #bfe5ee;
    --color-nav-active: #687b80;
}

div[data-env='dev'] {
    --color-header: #eed7bf;
    --color-nav-active: #80776c;
}

div[data-env='staging'] {
    --color-header: #c1eebf;
    --color-nav-active: #68806a;
}
