@import '../../styles/variables';

.skuUpdateSection {
    & > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .selectButtons > button:not(:first-child) {
            margin-left: 10px;
        }
    }

    & > .products {
        margin: 20px 0;
        max-height: 500px;
        overflow: scroll;
    }

    & > .footer {
        display: flex;
        justify-content: end;
    }
}

.cacheDecisionModalText {
    .showMoreLabel {
        color: var(--color-h1);
        text-decoration: underline dashed;
        cursor: pointer;
    }
}
