.packUsageSection {
    &:not(:first-child) {
        margin-top: 30px;
    }

    & > ul {
        padding-left: 20px;
        list-style-type: none;

        & > li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;

            &:hover {
                cursor: pointer;
                background: var(--color-secondary-light);
            }

            .inactive {
                color: #aaa;
            }
        }
    }
}
