@import '../../styles/variables';

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: var(--color-header);
    box-shadow: 0px 6px 0px 0px var(--color-header-box-shadow);

    ul {
        margin: 0;
    }

    .logo {
        margin: 15px 5px 5px 15px;
        height: 55px;
        float: left;
    }

    a {
        display: inline-block;
        margin: 28px 20px;
        text-decoration: none;

        &.logoWrapperLink {
            margin: 0;
            display: block;
        }

        &.active {
            color: var(--color-nav-active);
        }
    }
    & > .linksWrapper {
        width: 100%;
    }

    & > .childrenWrapper {
        display: flex;
        align-items: center;
    }

    .submenuWrapper {
        display: inline-block;
        cursor: pointer;
        position: relative;

        & .submenuButton {
            display: inline-block;
            margin: 28px 20px;
            font-size: 1em;
            color: var(--color-h1);
            background: none;
            text-decoration: none;
            padding: 0;
        }

        &:focus-within > .submenu {
            display: block;
        }

        & > .submenu {
            display: none;
            position: absolute;
            background: var(--color-white);
            border-radius: 7px;
            padding: 5px;
            box-shadow: rgba(0, 0, 0, 0.2) 2px 5px 11px;
            left: 16px;
            top: 48px;
            user-select: none;
            width: max-content;
            z-index: 1;

            & > a {
                display: block;
                margin: 0;
                padding: 10px 15px;
                border-radius: 7px;

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}
