.playset {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    transform-origin: left top;
    position: absolute;

    .controls {
        visibility: hidden;
        transform-origin: top left;
        position: absolute;
        padding: 20px 0;
        left: 0;
        z-index: 1000;
        label {
            cursor: pointer;
            margin-right: 0;
            &.upload {
                border-top-left-radius: 0;
            }
        }
        button {
            margin-left: 5px;
        }
        a {
            color: white;
            text-decoration: underline;
            display: block;
            margin: 3px;
        }
        .panel {
            padding: 10px;
            background: rgba(0, 0, 0, 0.6);
            color: white;
            min-width: 255px;
            border-radius: 7px;
            position: relative;

            img {
                width: 50px;
                display: block;
            }
        }
        .toggle {
            padding: 6px 4px 6px 4px;
            font-size: 0.85em;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            margin: 0;
            img {
                width: 15px;
            }
            &.leftToggle {
                border-top-right-radius: 0;
                background-color: var(--color-default);
                img {
                    opacity: 1;
                }
                &.isOwned {
                    background-color: var(--color-black);
                    img {
                        opacity: 0.5;
                    }
                }
            }
            &.rightToggle {
                border-top-left-radius: 0;
                background-color: var(--color-black);
                img {
                    opacity: 0.5;
                }
                &.isOwned {
                    background-color: var(--color-default);
                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.active {
        img {
            outline: 5px dotted white;
            outline-offset: 15px;
        }
        .controls {
            visibility: visible;
            img {
                outline: none;
            }
        }
    }

    &:hover {
        .controls {
            visibility: visible !important;
        }
    }
}

.arrowButton {
    padding: 8px;
}

.toggleContainer {
    position: absolute;
    left: 10px;
    top: 13px;
}
