.spoiler {
    .hiddenCheckbox {
        display: none;
    }

    .territoriesSpoiler {
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: max-height 0.1s ease-out;
    }

    .hiddenCheckbox:checked + .territoriesSpoiler {
        max-height: 500px;
        visibility: visible;
        transition-duration: 0.5s;
    }
}
