.table {
    display: flex;
    flex-direction: column;
}

.data {
    display: flex;
    align-items: center;
    padding-left: 12px;
    min-height: 48px;
}

.envName {
    margin: 0 8px;
}

.changeStatus {
    border: solid 1px;
    border-radius: 4px;
    padding: 2px 8px;
    margin-left: 8px;
    font-size: 0.8em;
    font-family: 'bold';
    text-transform: uppercase;

    &.new {
        border-color: #325700;
        color: #325700;
    }
    &.updated {
        border-color: #004f9e;
        color: #004f9e;
    }
    &.invalid {
        border-color: var(--color-danger-dark);
        color: var(--color-danger-dark);
    }
}
