@import '../../styles/variables';

.loginWrapper {
    text-align: center;
    background: var(--color-header);
    min-height: 100vh;
    display: flex;
    align-items: center;

    .centeredBlock {
        margin: auto;
        padding-bottom: 120px;

        .logoImg {
            width: 130px;
            margin: auto auto 20px;
            display: block;
        }

        .title {
            margin-bottom: 10px;
        }

        .buttonBlock {
            background: var(--color-white);
            display: inline-block;
            padding: 30px;
            border-radius: 20px;
        }
    }
}
