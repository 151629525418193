.controlPanel {
    display: flex;
    justify-content: space-between;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalContent {
    max-height: 50vh;
    overflow: auto;
}

.buttons {
    display: flex;
    justify-content: right;

    & > *:not(:last-child) {
        margin-right: 15px;
    }
}
