.mediaContainer {
    user-select: none;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    max-width: 290px;
    width: 100%;
    text-align: center;
    img,
    video {
        object-fit: scale-down;
        width: 100%;
        aspect-ratio: 1.5;
        background-color: var(--color-grey-background);
        border-radius: 6px;
    }
    .overlay {
        display: none;
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 230px;
        background: var(--color-white-ish);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 4px 6px;
        text-align: center;
        line-break: auto;
        overflow: hidden;
    }
    &:hover {
        cursor: pointer;
        .overlay {
            display: inline-block;
        }
    }
}

.buttonIcon {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center;
    width: 28px;
    height: 28px;
    padding: 6px;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 6px 0 6px;

    &.plus {
        background-color: white;
        background-image: url('../assets/plus-grey.svg');
        &:hover {
            background-color: var(--color-white-ish);
        }
    }

    &.check {
        background-color: var(--color-primary);
        background-image: url('../assets/check-white.svg');
        &:hover {
            background-color: var(--color-primary-dark);
            background-image: url('../assets/minus-white.svg');
        }
    }

    &.minus {
        background-color: var(--color-primary-dark);
        background-image: url('../assets/minus-white.svg');
    }

    &.delete {
        background-color: var(--color-danger);
        background-image: url('../assets/bin-white.svg');
        &:hover {
            background-color: var(--color-danger-dark);
        }
    }
}
