.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    justify-items: center;
    gap: 6px;
}

.gridCell {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 12px;
    &:hover {
        background-color: var(--color-grey-background);
    }
}

.imageWrapper {
    flex-grow: 1;
    padding: 2px;
}

.metadata {
    flex-grow: 0;
    font-family: standard;
    font-size: 0.8em;
    padding-left: 4px;
}

.fileName {
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
}

.timestamp {
    color: var(--color-dark-text);
}

.usages {
    display: inline-flex;
    align-items: center;
    padding: 4px 6px;
    margin-bottom: 8px;
    border-radius: 8px;
    gap: 4px;
    color: var(--color-dark-text);
    background-color: var(--color-grey-background);
}

.pagination {
    padding-top: 16px;
}
