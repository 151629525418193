.selector {
    margin: 0px;
    background-color: var(--color-secondary);
    color: var(--color-black);
    border: solid #cededc;
    border-width: 1px;
    border-radius: 8px;
    background-image: url('../assets/carret.svg');
    background-position: center right 10px;
}
