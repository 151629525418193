.summaryRow {
    display: flex;
    align-items: center;
    padding: 0 12px;
    min-height: 48px;
    justify-content: space-between;
}

.summary {
    display: flex;
    align-items: center;
}

.mediaList {
    border-top: solid 1px;
}

.mediaType {
    display: flex;
    align-items: center;
    background-color: var(--color-secondary);
    padding: 12px;
    font-family: 'standard';
}

.thumbnails {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: solid 1px;
    padding: 12px;
    gap: 4px;
}

.thumbnail {
    max-width: 128px;
}
