:root {
    --color-body: #dde9e7;
    --color-body-text: #3b4543;
    --color-header: #bfe5ee;
    --color-header-box-shadow: rgba(13, 38, 35, 0.15);
    --color-section-title: #c8cccb;
    --color-section-title-resting: #d5d9d8;
    --color-accordion-section-title: #e1e5e5;
    --color-accordion-section-title-resting: #eef2f1;
    --color-h1: #3b4543;
    --color-h2: #42404c;
    --color-speechbubble: #ffeee1;
    --color-grey-text: #909090;
    --color-grey-background: #f1f3f3;
    --color-dark-text: #677977;
    --color-white: #ffffff;
    --color-white-ish: #f3f3f3;
    --color-black: #0c0e0e;
    --color-black-light: #929292;
    --color-default: #3c3a44;
    --color-default-light: #a4a3a7;
    --color-primary: #11b0b2;
    --color-primary-light: #95d8d9;
    --color-primary-dark: #0d7b7c;
    --color-secondary: #ebf3f2;
    --color-secondary-light: #f5f9f8;
    --color-secondary-dark: #cededc;
    --color-positive: #20882e;
    --color-positive-light: #9ac59f;
    --color-warning: #f0ad4e;
    --color-warning-light: #f7d7ad;
    --color-danger: #ce1414;
    --color-danger-light: #e79897;
    --color-danger-dark: #990f0f;
    --color-nav-active: #759eaa;
}

$colors-mapsign: #fd5676, #56b0fd, #8ed365, #eb56fd;
