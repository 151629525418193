.featuresTextarea {
    margin-right: 30px;
}

.descriptionTextarea {
    background: none;
    resize: none;
    color: var(--color-default-light);
}

.newRow {
    width: 100%;
    margin-bottom: 20px;
}
