.tableWrapper {
    width: 100%;
}

.summary {
    display: flex;
    align-items: center;
    padding-left: 12px;
    min-height: 48px;
}

.table {
    width: 100%;

    .headerRow {
        background-color: var(--color-secondary);
        height: 48px;
    }

    .row {
        border-top: solid 1px;
        border-bottom: none;
    }

    .header {
        color: var(--color-h2);
        padding: 12px;
        border-top: solid 1px;

        &:not(:nth-child(1 of .header)) {
            border-left: solid 1px;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
    }

    .data {
        word-break: break-all;
        padding: 12px;
        font-family: 'standard';
        border-top: solid 1px;

        &:not(:nth-child(1 of .data)) {
            border-left: solid 1px;
        }
    }
}
