.verticalHeader {
    flex: 0 0 12%;
    padding-left: 12px;
    display: flex;
    align-items: center;
}

.horizontalHeader {
    flex: 0 0 41.5%;
    border-top: 1px solid;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'standard';
    text-transform: capitalize;

    &.new {
        background-color: #f4faea;
    }
    &.updated {
        background-color: #e0f0ff;
    }
    &.invalid {
        background-color: var(--color-secondary-dark);
    }
    &.noChanges {
        background-color: var(--color-secondary-light);
    }
}

:nth-child(1 of .horizontalHeader) {
    border-left: 1px solid;
    border-top-left-radius: 8px;
}

:nth-last-child(1 of .horizontalHeader) {
    border-top-right-radius: 8px;
}

.dataCell {
    flex: 0 0 41.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 48px;
    border-left: 1px solid;
}

:nth-last-child(1 of .dataCell) {
    border-right: 1px solid;
    background-color: var(--color-secondary-light);
}

.expandCell {
    flex: 0 0 5%;
    padding-left: 12px;
}
