@import '../../styles/variables';

.Form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ErrorMessage {
    color: var(--color-danger);
    font-size: 12px;
    font-family: 'standard';
    margin: 5px 0 0 0;
}
