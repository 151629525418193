@import '../../styles/variables';

.productRow {
    display: flex;
    align-items: center;
    padding: 10px 0;

    & .checkbox {
        margin-right: 10px;
    }

    & > .status {
        min-width: 150px;
        margin-left: 20px;

        &.notStarted {
        }
        &.updating {
            color: var(--color-positive-light);
        }
        &.scheduled {
        }
        &.updated {
            color: var(--color-positive);
        }
        &.invalid,
        &.error {
            color: var(--color-danger);
            text-decoration: underline dotted 3px;

            &:hover {
                cursor: help;
            }
        }
    }

    & .errorMessage {
        color: var(--color-danger);
        font-size: 12px;
        font-family: 'standard';
        margin: 5px 0 0 0;
    }
}
