.footer {
    display: flex;
    align-items: center;
    margin-top: 4px;

    &.justifyEnd {
        justify-content: end;
    }

    &.justifyBetween {
        justify-content: space-between;
    }
}
